<template>
  <div id="b2c" class="main-body">
    <div id="nav-white" />
    <div id="services-front" class="seaff-bg">
      <h1 class="zoomIn">Sea Freight Forwarding</h1>
    </div>

    <div id="content">
      <h1 class="fadeIn">Brief Summary</h1>
      <p class="fadeIn">
        Sea Freight involves the transportation of goods by cargo ships across
        oceans. It is the most cost-effective way to ship large quantities of
        goods internationally but comes with longer transit times.
      </p>
    </div>

    <visit-system
      name="B2BPRO"
      link="https://b2bpro.golog.my/login"
      :is-b2b="true"
      class="fadeIn"
    />
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";
import VisitSystem from "@/components/VisitSystem.vue";

export default {
  components: { VisitSystem },
  mixins: [intersectionObserverMixin],
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.5);
    this.setupIntersectionObserver(".zoomIn", 0.1);
  },
};
</script>
